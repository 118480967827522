import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import App from '../components/App/App';
import Components from '../components/Components';
import Nav from '../components/Nav/Nav';
import ImageHero from '../components/ImageHero/ImageHero';
import BackgroundPebbles from '../components/BackgroundPebbles/BackgroundPebbles';
import StaticBackgroundPebbles from '../components/StaticBackgroundPebbles/StaticBackgroundPebbles';
import Footer from '../components/Footer/Footer';
import PhysicsIcons from '../components/PhysicsIcons/PhysicsIcons';

const HowWeLook = ({ data }) => {
  const { title, contentBlocks, footer, imageHero } = data.sanityHowWeLookPage;

  useEffect(() => {
    document.body.classList.add('bg-white');
  }, []);

  // todo: kind of hacky solution, but it works for now. Should optimally be added as a field in sanity and the component implemented in the components serializer.
  const contentBlockComponents = contentBlocks.map((block) => {
    if (block._type === 'textBlockSection') {
      block.backgroundType = 'pebbles-1';
    }

    return Components(block);
  });

  contentBlockComponents.splice(1, 0, <PhysicsIcons key="physicsIcons" />);

  return (
    <App>
      <Nav theme="light" hasDarkHeader />
      <ImageHero data={imageHero} />
      {/* <HeroBackgroundColor title={title} /> */}
      {contentBlockComponents}
      <Footer data={footer} />
    </App>
  );
};

export const query = graphql`
  {
    sanityHowWeLookPage {
      id
      title

      imageHero {
        title
        _rawBackgroundImage
      }

      contentBlocks {
        ... on SanityTextBlockSection {
          _key
          _type
          textBlocks {
            title
            _rawText
          }
        }
        ... on SanityTextSection {
          _key
          _type
          _rawText
          bgcolor
          heading
        }

        ... on SanityPebbleIconSection {
          _key
          _type
          pebbleText
          pebbleTitle
        }

        ... on SanityInfoBoxSection {
          _key
          _type
          infoBoxes {
            icon {
              asset {
                path
                url
                title
              }
            }
            text {
              text {
                style
                list
              }
              placement
            }
          }
          title
        }

        ... on SanityPebbleFontPreviewSection {
          _key
          _type
          _rawFooter
          _rawHead
        }
      }
      footer {
        text
        link {
          path
          title
        }
      }
    }
  }
`;

export default HowWeLook;
