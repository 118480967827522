import React, { useEffect, useRef, useState } from 'react';

import { Engine, Render, Runner, Body, Bodies, Mouse, MouseConstraint, World } from 'matter-js';

import { getRandomPosition, getRandomInt } from '../../utils/getRandomPosition';
import resetScene from '../../utils/matter/resetScene';
import window from '../../utils/window';

import useWindowSize from '../../hooks/useWindowSize';
import useInView from '../../hooks/useInView';

import disableTouch from '../../utils/matter/disableTouch';

import globeIcon from '../../images/icons/globe.png';
import smileyIcon from '../../images/icons/smiley.png';
import gearIcon from '../../images/icons/gear.png';
import heartIcon from '../../images/icons/heart.png';
import textImage from '../../images/icons/text.png';
import textLineOne from '../../images/icons/text-line-1.png';
import textLineTwo from '../../images/icons/text-line-2.png';
import textLineThree from '../../images/icons/text-line-3.png';

import * as styles from './PhysicsIcons.module.scss';

/**
 * This is the PhysicsIcons component.
 *
 * @author Maximilian Øystå Lloyd <max@apt.no>
 *
 * @return {JSX}
 */

const WINDOW_SIZE_MOBILE = 750;
const WINDOW_SIZE_TABLET = 1180;
const PIXEL_RATIO = Math.min(1.75, window.devicePixelRatio);

const PhysicsIcons = () => {
  const matterContainer = useRef();
  const bodiesRef = useRef({
    roof: null,
    floor: null,
    leftWall: null,
    rightWall: null,
    textBox: null,
  });

  const [isInitialRenderDone, setIsInitialRenderDone] = useState(false);
  const [matterScene, setMatterScene] = useState();

  const inView = useInView(matterContainer);
  const windowSize = useWindowSize();
  const prevWidthRef = useRef(windowSize.width);

  function initScene(scale = 1) {
    const isMobile = window.innerWidth <= WINDOW_SIZE_MOBILE;
    const isTablet = window.innerWidth <= WINDOW_SIZE_TABLET;

    const canvasHeight = isMobile
      ? Math.max(500, window.innerHeight * 0.6)
      : Math.max(650, window.innerHeight * 0.9);
    const canvasWidth = window.innerWidth;
    const centerHeight = canvasHeight / 2;
    const centerWidth = canvasWidth / 2;

    /**
     * Settings for scaling
     */
    let settings = { iconScale: 1, textSize: 0.7, textMargin: 10 };

    if (isTablet) {
      settings = { ...settings, iconScale: 1 };
    }

    if (window.innerWidth <= 770) {
      settings = { ...settings, iconScale: 0.5, textSize: 0.65 };
    }

    if (isMobile) {
      settings = { ...settings, iconScale: 0.8, textSize: 0.55 };
    }

    if (window.innerWidth <= 320) {
      settings = { ...settings, textSize: 0.48 };
    }

    const engine = Engine.create();
    const render = Render.create({
      element: matterContainer.current,
      engine,
      options: {
        width: canvasWidth,
        height: canvasHeight,
        wireframes: false,
        background: 'transparent',
        wireframeBackground: 'transparent',
      },
    });
    const runner = Runner.create();

    const { world } = engine;

    /**
     * Add circle icons
     */
    const icons = [globeIcon, globeIcon, smileyIcon, smileyIcon, gearIcon, heartIcon];
    const iconSize = 105;
    const { iconScale } = settings;
    const iconsCircles = icons.map((iconUrl, iconIndex) => {
      const radius = iconSize * iconScale * scale;
      const diameter = radius * 2;
      const randomPos = getRandomPosition(canvasWidth, canvasHeight, radius);

      return Bodies.circle(randomPos.x, -1 * (diameter * iconIndex), radius, {
        density: 1,
        frictionAir: getRandomInt(0, 0.3) / 10,
        restitution: getRandomInt(4, 8) / 10,
        friction: getRandomInt(6, 11) / 10,
        render: {
          sprite: {
            texture: iconUrl,
            xScale: scale * iconScale,
            yScale: scale * iconScale,
          },
        },
      });
    });

    World.add(world, iconsCircles);

    /**
     * Static text
     */
    const { textSize } = settings;
    const { textMargin } = settings;
    const textsLines = [
      {
        el: textLineOne,
        width: 892 * textSize,
        height: 72 * textSize,
        y: centerHeight - (72 + textMargin) * textSize * scale,
        x: centerWidth,
      },
      {
        el: textLineTwo,
        width: 1450 * textSize,
        height: 88 * textSize,
        y: centerHeight,
        x: centerWidth,
      },
      {
        el: textLineThree,
        width: 1020 * textSize,
        height: 84 * textSize,
        y: centerHeight + (84 + textMargin) * textSize * scale,
        x: centerWidth,
      },
    ];

    if (isMobile) {
      const textPadding = canvasWidth * 0.06;
      textsLines[0].x = 0 + textPadding + (textsLines[0].width / 2) * scale;
      textsLines[1].x = 0 + textPadding + (textsLines[1].width / 2) * scale;
      textsLines[2].x = 0 + textPadding + (textsLines[2].width / 2) * scale;

      const line1Height = textsLines[0].height * scale;
      const line2Height = textsLines[1].height * scale;
      const line3Height = textsLines[2].height * scale;

      textsLines[0].y = canvasHeight - (textPadding + line1Height + line2Height + line3Height);
      textsLines[1].y = canvasHeight - (textPadding + line2Height + line3Height);
      textsLines[2].y = canvasHeight - (textPadding + line3Height);
    }

    const lineRefs = [];
    textsLines.forEach((line) => {
      const textBox = Bodies.rectangle(line.x, line.y, line.width * scale, line.height * scale, {
        isStatic: true,
        render: {
          sprite: { texture: line.el, xScale: scale * textSize, yScale: scale * textSize },
        },
      });

      World.add(world, [textBox]);
      lineRefs.push(textBox);
    });

    /**
     * Canvas world borders
     */

    const ground = Bodies.rectangle(centerWidth, canvasHeight + 10, canvasWidth, 20, {
      isStatic: true,
    });
    const leftWall = Bodies.rectangle(-10, centerHeight, 20, canvasHeight, {
      isStatic: true,
    });
    const rightWall = Bodies.rectangle(canvasWidth + 10, centerHeight, 20, canvasHeight, {
      isStatic: true,
    });

    World.add(world, [ground, leftWall, rightWall]);

    bodiesRef.current = {
      leftWall,
      rightWall,
      ground,
    };

    const mouse = Mouse.create(render.canvas);
    mouse.pixelRatio = PIXEL_RATIO;

    const mouseConstraint = MouseConstraint.create(engine, {
      element: render.canvas,
      mouse,
      constraint: {
        render: {
          visible: false,
        },
      },
    });

    disableTouch(mouseConstraint);

    world.gravity.y = 1;

    Render.setPixelRatio(render, PIXEL_RATIO);

    World.add(world, [mouseConstraint]);
    setIsInitialRenderDone(true);
    setMatterScene({ render, engine, runner });
  }

  function initSceneBasedOnWidth() {
    if (window.innerWidth <= WINDOW_SIZE_MOBILE) {
      initScene(0.4);
    } else if (window.innerWidth <= WINDOW_SIZE_TABLET) {
      initScene(0.6);
    } else {
      initScene(0.8);
    }
  }

  useEffect(() => {
    initSceneBasedOnWidth();
  }, []);

  useEffect(() => {
    const sizeIsDifferent = windowSize.width !== prevWidthRef.current && prevWidthRef.current;

    if (isInitialRenderDone && sizeIsDifferent) {
      const bodies = bodiesRef.current;
      const centerWidth = windowSize.width / 2;
      const centerHeight = (matterScene?.render?.canvas?.height || 900) / 2;

      Body.setPosition(bodies.rightWall, {
        x: windowSize.width + 10,
        y: windowSize.height / 2,
      });

      // Body.setPosition(bodies.lineRefs, { x: centerWidth, y: centerHeight });

      matterScene.render.canvas.width = windowSize.width;

      // if (windowSize.width <= WINDOW_SIZE_MOBILE) {
      clearTimeout(window.resizeTimer);
      window.resizeTimer = setTimeout(() => {
        if (!matterScene.render.canvas) return;
        resetScene(matterScene);
        initSceneBasedOnWidth();
      }, 500);
      // }
    }

    prevWidthRef.current = windowSize.width;
  }, [windowSize.width, isInitialRenderDone]);

  // stop and start based on wheter it is in view or not
  useEffect(() => {
    if (!matterScene) return;

    if (!inView) {
      Render.stop(matterScene.render);
      Runner.stop(matterScene.runner);
    } else {
      Render.run(matterScene.render);
      Runner.start(matterScene.runner, matterScene.engine);
    }
  }, [inView, matterScene]);

  return (
    <div className={styles.main}>
      <div className={styles.wrapper} ref={matterContainer} />
    </div>
  );
};

export default PhysicsIcons;
