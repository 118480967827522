export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomPosition(width, height, radius) {
  const minWidth = radius;
  const maxWidth = width - radius;

  const minHeight = radius;
  const maxHeight = height - radius;

  const x = getRandomInt(minWidth, maxWidth);
  const y = getRandomInt(minHeight, maxHeight);

  return {
    x,
    y
  };
}
