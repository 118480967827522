import React from 'react';
import PropTypes from 'prop-types';

import urlFor from '../../sanity/urlFor';

import * as styles from './ImageHero.module.scss';

const ImageHero = ({ data }) => {
  const { title, _rawBackgroundImage } = data;

  const backgroundImageUrl = urlFor(_rawBackgroundImage.asset).url();

  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className={styles.container}>
        <h1 className={styles.imageHeroHeader}>{title}</h1>
      </div>
    </div>
  );
};

ImageHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    _rawBackgroundImage: PropTypes.shape.isRequired,
  }).isRequired,
};

export default ImageHero;
