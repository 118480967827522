import sanityClient from '@sanity/client';

// t
const client = sanityClient({
  projectId: 'khkyn1dn',
  dataset: 'production',
  token:
    'sktBTzyF2cxmlevnDJ4tHPS4F5zK5zy0WCZ4rhaL453fvnFsiROFjYjBs7iPirJU4wKEHaUw3dVGawwJ0g2z1zOX0eRwaIAkBtxIGMgJ1vXHIkYU6nhQ7e6j0qqOv2FxuofCExwqjjqqdwv3VGZjWXQ4x4WbPzHyZhJDUPtftFf2jljmx7RC',
  // token: "sknoOmcblRrx29QmufNOSwdMV59wxhelh7Xt2DY80ev2v2eBr1WSzl2SzoTcH0BAKzDdZaZobvTSVHgl57K4qygxYIa74I5XPP8LqNcEP8accxvWltLRU3y7XJXdTMw9TFOGp6gxUdy2OJSLT3uNAqjcdwykxg7FVWXEVKoBUVGbKMHA1sEX", // or leave blank to be anonymous user
  useCdn: false, // `false` if you want to ensure fresh data
});

export default client;
